@charset "utf-8";

@import "_settings";
@import "_foundation";

// vendor styles
// @import "vendor/aos";
// @import "vendor/slick-carousel";
// @import "vendor/index-menu";

$fa-font-path: '../webfonts';
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss"; // https://fontawesome.com/icons?d=gallery&s=regular&m=free
@import "~@fortawesome/fontawesome-pro/scss/solid.scss"; // https://fontawesome.com/icons?d=gallery&s=solid&m=free
@import "~@fortawesome/fontawesome-pro/scss/brands.scss"; // https://fontawesome.com/icons?d=gallery&s=brands&m=free

h1,h2,h3,h4,h5,h6 {
    font-family: 'DIN Next LT W01 Light', Helvetica, Roboto, Arial, sans-serif !important;
}

.bold, strong {
    font-family: 'DIN Next LT W01 Bold', Helvetica, Roboto, Arial, sans-serif !important;
}

.lead {
    @include breakpoint(large up) {
        font-size: 42px;
    }
}

html, body {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
}

nav.main-nav {
    position: fixed;
    top:0;
    width:100%;
    background-color: $dark-blue;
    a {
        color: $white;
    }
}

.content {
    flex: 1 0 auto;
    section.section {
        padding-top: 5rem;
        padding-bottom: 5rem;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        &.full {
            min-height: 100vh;
        }

        &#home {
            display: flex;
            & > .grid-container { align-self: flex-end; }
        }
    }
}

.bg-blue {
    color: $white;
    background-color: $blue;

    &.bg-transparent {
        background-color: rgba($blue, 0.5);
    }
}

.color-white {
    color: $white;
}

.color-blue {
    color: $blue;
}

.box {
    padding: 1rem;
}

.bold {
    font-weight: $global-weight-bold;
}

p.h1 {
    font-weight: $global-weight-bold;
}

dl.auflistung {
    width: 100%;
    overflow: hidden;

    dt {
        float: left;
        width: 100px;
        font-style: inherit;
        margin: 0;
    }

    dd {
        margin: 0 0 0 110px;
        padding-bottom: 10px;
    }
}

.lift-off {
    color: $white;
    font-size: 2rem;
    display: block;
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    visibility: visible;
    opacity: 1;
    background-color: $dark-blue;
    height: 50px;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    transition: opacity 0.25s ease-in-out, background-color 0.25s ease;
    z-index: 1000;
    &:hover, &:focus, &:active {
        color: $white;
        background-color: lighten($dark-blue, 5%);
    }
    &.lift-off-hidden {
        opacity: 0;
    }
}
footer.main-footer {
    background-color: $blue;
    color: $white;
    p { margin: 0; }
}
